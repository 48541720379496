import React from "react";
import styled, { css } from "styled-components";
import { Stack } from "../StyledComponents/mixdown.styles";
import { mediaQueries } from "../styles/variables";

const StyledLabel = styled.label`
  font-family: "neue-haas-grotesk-text";
  font-size: 10px;
  line-height: 12px;
`;

const StyledTextArea = styled.textarea`
  font-family: "neue-haas-grotesk-text";
  font-size: 18px;
  line-height: 24px;
  background-color: black;
  color: ${({ isError, value }) =>
    isError && value === "" ? "#d82a2a" : "#DADADA"};
  border: 2px solid white;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  outline: none;
  padding: 1px 6px 1px 6px;
  border-radius: 0;
  resize: none;
  position: relative;

  &::placeholder {
    color: ${({ isError, value }) =>
      isError && value === "" ? "#d82a2a" : "#d9d9d9"};
    opacity: ${({ isError }) => (isError ? 1 : 0.5)};
  }

  &:focus-within {
    color: white;
  }

  input::-webkit-outer-spin-button {
    display: none;
  }

  ${mediaQueries.small} {
    border-radius: 0;
  }
`;

const StyledP = styled.p`
  font-family: "neue-haas-grotesk-text";
  color: #d82a2a;
  font-size: 10px;
  line-height: 12px;
`;

const StyledRedMessageContainer = styled.div`
  position: absolute;
  right: -60px;
  top: 3px;

  ${mediaQueries.small} {
    right: 37.5%;
    display: flex;
    gap: 2px;
    top: 35px;
  }
`;

const StyledStack = styled(Stack)`
  width: 100%;
  position: relative;

  ${mediaQueries.small} {
    ${({ isError }) =>
      isError &&
      css`
        &:last-child {
          margin-bottom: 18px;
        }
      `}
  }
`;

export const TextAreaWithLabel = ({
  name,
  type,
  text,
  width,
  value,
  onChange,
  placeholder,
  inputStyle,
  register,
  required = true,
  errors,
  stackStyle,
}) => {
  const isError = errors[name] && value === "";
  return (
    <StyledStack gap="5px" isError={isError} style={{ ...stackStyle }}>
      {text && <StyledLabel labelFor={name}>{text}</StyledLabel>}
      <div
        style={{
          position: "relative",
        }}
      >
        <StyledTextArea
          type={type}
          name={name}
          width={width}
          {...register(name, { required })}
          value={value}
          onChange={onChange}
          placeholder={Boolean(errors[name]) ? "-" : placeholder}
          style={inputStyle}
          isError={Boolean(errors[name])}
          rows="3"
        />
        {isError && (
          <StyledRedMessageContainer>
            <StyledP>Missing</StyledP>
            <StyledP>information</StyledP>
          </StyledRedMessageContainer>
        )}
      </div>
    </StyledStack>
  );
};
