import React, { useContext } from "react";
import { navigate } from "gatsby";

import Toggle from "react-toggle";
import { FormContext } from "../../context/formContext";

import SEO from "../seo";
import {
  MixdownWrapper,
  LeftWrapper,
  MixdownTitle,
  Description,
  SmallText,
  Stack,
  Inline,
} from "../../StyledComponents/mixdown.styles";
import { SubmitButton } from "../SubmitButton";
import { BackButton } from "../BackButton";
import { InputWithLabel } from "../InputWithLabel";
import { useForm } from "react-hook-form";
import { TextAreaWithLabel } from "../../components/TextAreaWithLabel";

const MixdownFormMobile = ({ data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    orderTitleLeft,
    orderDescriptionLeft,
    fileSizeInfo,
    orderDescriptionRight,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const {
    bandOrElectronic,
    setBandOrElectronic,
    restoration,
    setRestoration,
    masterEffect,
    setMasterEffect,
    bpm,
    setBpm,
    trackQuantity,
    setTrackQuantity,
    stemsPerSong,
    setStemsPerSong,
    listeningLink,
    setListeningLink,
    projectLink,
    setProjectLink,
    firstTrack,
    setFirstTrack,
    additionalRequest,
    setAdditionalRequest,
  } = useContext(FormContext);

  const onSubmit = () => {
    if (Object.keys(errors).length === 0) {
      navigate("/mixdown/contact");
    }
  };

  return (
    <>
      <SEO title="SPANDAU20 Mixdown" />

      {/* <FixedHeaderButtons
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <BackButton />
          <SubmitButton isYellow={false}>Next</SubmitButton>
        </FixedHeaderButtons> */}

      <MixdownWrapper gap="48px">
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "48px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <BackButton />
            <SubmitButton isYellow={false}>Next</SubmitButton>
          </div>
          <LeftWrapper gap="24px">
            <Stack gap="24px">
              <MixdownTitle>{orderTitleLeft}</MixdownTitle>
              <SmallText>{orderDescriptionLeft}</SmallText>
            </Stack>

            <Inline
              alignItems="center"
              justifyContent="center"
              style={{ width: "100%" }}
              gap="8px"
            >
              <div style={{ textAlign: "right" }}>
                <SmallText>Band / </SmallText>
                <SmallText>unplugged</SmallText>
              </div>
              <Toggle
                onChange={() => setBandOrElectronic(!bandOrElectronic)}
                checked={bandOrElectronic}
                icons={false}
              />
              <div>
                <SmallText>Electronic</SmallText>
                <SmallText>production</SmallText>
              </div>
            </Inline>
            <Stack gap="4px">
              <Inline justifyContent="space-between">
                <SmallText>Track quantity</SmallText>
                <SmallText>Type 1-100</SmallText>
              </Inline>
              <InputWithLabel
                name="Track quantity"
                type="text"
                value={trackQuantity}
                onChange={e => setTrackQuantity(e.target.value)}
                register={register}
                errors={errors}
              />
            </Stack>
            <Stack gap="4px">
              <Inline justifyContent="space-between">
                <SmallText>Stems per song</SmallText>
                <SmallText>Type 1-300</SmallText>
              </Inline>
              <InputWithLabel
                name="Stems per song"
                type="text"
                value={stemsPerSong}
                onChange={e => setStemsPerSong(e.target.value)}
                register={register}
                errors={errors}
              />
            </Stack>
            <InputWithLabel
              name="Listening link"
              text="Listening link"
              type="text"
              value={listeningLink}
              onChange={e => setListeningLink(e.target.value)}
              register={register}
              errors={errors}
            />
            <Stack gap="8px">
              <InputWithLabel
                name="Full project download link"
                text="Full project download link"
                type="text"
                value={projectLink}
                onChange={e => setProjectLink(e.target.value)}
                placeholder="e.g. http://www.soundcloud.com/coolmusic"
                register={register}
                errors={errors}
              />
              <SmallText
                style={{
                  borderLeft: "2px solid white",
                  paddingLeft: "23px",
                  marginTop:
                    !projectLink && errors["Full project download link"]
                      ? 15
                      : 0,
                }}
              >
                {fileSizeInfo}
              </SmallText>
            </Stack>
            <Stack gap="4px">
              <Inline justifyContent="space-between">
                <SmallText>BPM</SmallText>
                <SmallText>Type 1-400</SmallText>
              </Inline>

              <InputWithLabel
                name="BPM"
                type="text"
                value={bpm}
                onChange={e => setBpm(e.target.value)}
                register={register}
                errors={errors}
              />
            </Stack>
            <Stack gap="4px" alignItems="center">
              <SmallText>Restoration</SmallText>
              <Inline gap="8px" alignItems="center" justifyContent="center">
                <SmallText>Yes</SmallText>
                <Toggle
                  onChange={() => setRestoration(!restoration)}
                  checked={restoration}
                  icons={false}
                />
                <SmallText>No</SmallText>
              </Inline>
            </Stack>
            <Stack gap="4px" alignItems="center" style={{ paddingRight: 12 }}>
              <SmallText style={{ paddingLeft: 12 }}>Master Effect</SmallText>
              <Inline gap="8px" alignItems="center" justifyContent="center">
                <SmallText>without</SmallText>
                <Toggle
                  onChange={() => setMasterEffect(!masterEffect)}
                  checked={masterEffect}
                  icons={false}
                />
                <SmallText>with</SmallText>
              </Inline>
            </Stack>

            <InputWithLabel
              name="First track"
              text="First track (Pitch +
                Reference Download + Listening Link)"
              type="text"
              value={firstTrack}
              onChange={e => setFirstTrack(e.target.value)}
              placeholder="e.g. http://www.soundcloud.com/coolmusic"
              register={register}
              errors={errors}
            />
            <TextAreaWithLabel
              name="Additional Request"
              text="Additional Request"
              type="text"
              value={additionalRequest}
              onChange={e => setAdditionalRequest(e.target.value)}
              placeholder="e.g.  “I would prefer XY as my  mixdown engineer.”"
              register={register}
              errors={errors}
              required={false}
            />
            <Description style={{ fontSize: 18, lineHeight: 1.25, padding: 0 }}>
              {orderDescriptionRight}
            </Description>
          </LeftWrapper>
        </form>
      </MixdownWrapper>
    </>
  );
};

export default MixdownFormMobile;
