import React, { useContext } from "react";
import { graphql, navigate } from "gatsby";

import { useWindowSize } from "../../lib/useWindowSize";
import { screenSizes } from "../../styles/variables";
import Toggle from "react-toggle";
import { FormContext } from "../../context/formContext";

import SEO from "../../components/seo";
import {
  MixdownWrapper,
  OrderLeftWrapper,
  MixdownTitle,
  Description,
  SmallText,
  Container,
  RightWrapper,
  Stack,
  OrderScrollWrapper,
  ArrowImage,
  Inline,
} from "../../StyledComponents/mixdown.styles";
import { SubmitButton } from "../../components/SubmitButton";
import { BackButton } from "../../components/BackButton";
import { InputWithLabel } from "../../components/InputWithLabel";
import { TextAreaWithLabel } from "../../components/TextAreaWithLabel";
import { useForm } from "react-hook-form";
import MixdownFormMobile from "../../components/mixdownMobilePages/OrderMobile";

const MixdownForm = ({ data }) => {
  const { width, height } = useWindowSize();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const isBelowTablet = width <= screenSizes.tablet;
  const isBelowOptimum = width <= 1024;

  const isScrollable = height < 740;

  const {
    orderTitleLeft,
    orderDescriptionLeft,
    orderDescriptionRight,
    fileSizeInfo,
  } = data.allFile.edges[0].node.childMarkdownRemark.frontmatter;

  const {
    bandOrElectronic,
    setBandOrElectronic,
    restoration,
    setRestoration,
    masterEffect,
    setMasterEffect,
    bpm,
    setBpm,
    trackQuantity,
    setTrackQuantity,
    stemsPerSong,
    setStemsPerSong,
    listeningLink,
    setListeningLink,
    projectLink,
    setProjectLink,
    firstTrack,
    setFirstTrack,
    additionalRequest,
    setAdditionalRequest,
  } = useContext(FormContext);

  const onSubmit = () => {
    if (Object.keys(errors).length === 0) {
      navigate("/mixdown/contact");
    }
  };

  return (
    <>
      <SEO title="SPANDAU20 Mixdown" />
      {isScrollable ? <ArrowImage /> : null}
      {!isBelowTablet ? (
        <>
          <MixdownWrapper>
            <form
              style={{
                display: "flex",
                height: "100%",
              }}
              onSubmit={handleSubmit(onSubmit)}
            >
              <OrderLeftWrapper style={{ gap: 10 }}>
                <MixdownTitle>{orderTitleLeft}</MixdownTitle>
                <SmallText>{orderDescriptionLeft}</SmallText>
                <OrderScrollWrapper style={{ gap: 10 }}>
                  <Container alignItems="flex-start">
                    <SmallText>
                      Brand/Unplugged OR Electronic productions
                    </SmallText>
                    <Container style={{ paddingRight: "13px" }}>
                      <div style={{ textAlign: "right" }}>
                        <SmallText>Band / </SmallText>
                        <SmallText>unplugged</SmallText>
                      </div>
                      <Toggle
                        onChange={() => setBandOrElectronic(!bandOrElectronic)}
                        checked={bandOrElectronic}
                        icons={false}
                      />
                      <div>
                        <SmallText>Electronic</SmallText>
                        <SmallText>production</SmallText>
                      </div>
                    </Container>
                  </Container>
                  <Container alignItems="flex-start">
                    <SmallText>Track quantity</SmallText>
                    <Container>
                      <div style={{ textAlign: "right" }}>
                        <SmallText>Type</SmallText>
                        <SmallText style={{ whiteSpace: "nowrap" }}>
                          1-100
                        </SmallText>
                      </div>
                      <InputWithLabel
                        name="Track quantity"
                        type="number"
                        width={123}
                        value={trackQuantity}
                        onChange={e => setTrackQuantity(e.target.value)}
                        inputStyle={{ textAlign: "right" }}
                        register={register}
                        errors={errors}
                        min={1}
                        max={100}
                        placeholder="e.g. 50"
                      />
                    </Container>
                  </Container>
                  <Container alignItems="flex-start">
                    <SmallText>Stems per song</SmallText>
                    <Container>
                      <div style={{ textAlign: "right" }}>
                        <SmallText>Type</SmallText>
                        <SmallText style={{ whiteSpace: "nowrap" }}>
                          1-300
                        </SmallText>
                      </div>
                      <InputWithLabel
                        inputStyle={{ textAlign: "right" }}
                        name="Stems per song"
                        type="number"
                        width={123}
                        value={stemsPerSong}
                        onChange={e => setStemsPerSong(e.target.value)}
                        register={register}
                        errors={errors}
                        min={1}
                        max={300}
                        placeholder="e.g. 100"
                      />
                    </Container>
                  </Container>
                  <InputWithLabel
                    name="Listening link"
                    text="Listening link"
                    type="text"
                    value={listeningLink}
                    onChange={e => setListeningLink(e.target.value)}
                    register={register}
                    errors={errors}
                  />
                  <Stack gap="8px">
                    <InputWithLabel
                      name="Full project download link"
                      text="Full project download link"
                      type="text"
                      value={projectLink}
                      onChange={e => setProjectLink(e.target.value)}
                      placeholder="e.g. http://www.soundcloud.com/coolmusic"
                      register={register}
                      errors={errors}
                    />
                    <SmallText
                      style={{
                        borderLeft: "2px solid white",
                        paddingLeft: "23px",
                      }}
                    >
                      {fileSizeInfo}
                    </SmallText>
                  </Stack>
                  <Container alignItems="flex-start">
                    <SmallText>BPM</SmallText>
                    <Container>
                      <div style={{ textAlign: "right" }}>
                        <SmallText>Type</SmallText>
                        <SmallText style={{ whiteSpace: "nowrap" }}>
                          1-400
                        </SmallText>
                      </div>
                      <InputWithLabel
                        name="BPM"
                        type="number"
                        width={123}
                        value={bpm}
                        onChange={e => setBpm(e.target.value)}
                        inputStyle={{ textAlign: "right" }}
                        register={register}
                        errors={errors}
                        min={1}
                        max={400}
                        placeholder="e.g. 120"
                      />
                    </Container>
                  </Container>
                  <Container alignItems="flex-start">
                    <SmallText>Restoration</SmallText>
                    <Container style={{ paddingRight: "50px" }}>
                      <div style={{ textAlign: "right" }}>
                        <SmallText>Yes</SmallText>
                      </div>
                      <Toggle
                        onChange={() => setRestoration(!restoration)}
                        checked={restoration}
                        icons={false}
                      />
                      <div>
                        <SmallText>No</SmallText>
                      </div>
                    </Container>
                  </Container>
                  <Container alignItems="flex-start">
                    <SmallText>Master Effect</SmallText>
                    <Container>
                      <div style={{ textAlign: "right" }}>
                        <SmallText>without</SmallText>
                        <SmallText>Master Effect</SmallText>
                      </div>
                      <Toggle
                        onChange={() => setMasterEffect(!masterEffect)}
                        checked={masterEffect}
                        icons={false}
                      />
                      <div>
                        <SmallText>with</SmallText>
                        <SmallText>Master Effect</SmallText>
                      </div>
                    </Container>
                  </Container>

                  <InputWithLabel
                    name="First track"
                    text="First track (Pitch +
                Reference Download + Listening Link)"
                    type="text"
                    value={firstTrack}
                    onChange={e => setFirstTrack(e.target.value)}
                    placeholder="e.g. http://www.soundcloud.com/coolmusic"
                    register={register}
                    errors={errors}
                  />
                </OrderScrollWrapper>
              </OrderLeftWrapper>
              <RightWrapper
                style={{
                  marginLeft: isBelowOptimum ? "2vw" : "13vw",
                }}
              >
                <TextAreaWithLabel
                  name="Additional Request"
                  text="Additional Request"
                  type="text"
                  value={additionalRequest}
                  onChange={e => setAdditionalRequest(e.target.value)}
                  placeholder="e.g.  “I would prefer XY as my  mixdown engineer.”"
                  register={register}
                  errors={errors}
                  required={false}
                  stackStyle={{ marginTop: 60 }}
                />
                <Description>{orderDescriptionRight}</Description>
                <Inline justifyContent="space-between">
                  <BackButton />
                  <SubmitButton isYellow={false} isHoverStyle>
                    Next
                  </SubmitButton>
                </Inline>
              </RightWrapper>
            </form>
          </MixdownWrapper>
        </>
      ) : (
        <MixdownFormMobile data={data} />
      )}
    </>
  );
};

export default MixdownForm;

export const query = graphql`
  query OrderQuery {
    allFile(filter: { sourceInstanceName: { eq: "mixdownOrder" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              orderTitleLeft
              orderDescriptionLeft
              orderDescriptionRight
              fileSizeInfo
            }
          }
        }
      }
    }
  }
`;
